<template>
  <page :styleWrap='{background: "#fff"}' ref='wrap'>
    <div class="detailPage" ref='content'>
      <header-bar :height='60'>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
        <div>
          <div class="f_flex f_j_c" v-if='groupInfo'>
            <popup-dialog
              v-model='show'
              @pick='changeGroupName'
              :desc='companyName'
              :id='groupInfo.groupId'
              text='groupName'
              :list='groupList'>
              <p style="font-size: 17px; color: #000">{{groupInfo.groupName}}</p>
            </popup-dialog>
          </div>
        </div>
        <div slot='action' @click="goInfo">
          <i class="iconfont icon-more" style='font-size: 30px; font-weight: 600'></i>
        </div>
      </header-bar>

      <div class="search f_flex f_a_c f_j_c">
        <search class="f_g1" shape='round' v-model='word' placeholder="搜索"></search>
      </div>
      <div>
        <info-panel
          v-for='(item, index) in postList'
          :key='index'
          :info='item'
          :index='index'
          :keywordEvent='true'
          :showStar='false'
           />
      </div>
      <div>
        <img v-if='showPost' @click="postFn" style="bottom: 30px" class="postIcon transform_r_c act_link" src="../../assets/icon_post.png" alt="">
      </div>
    </div>
  </page>
</template>

<script>
import infoPanel from '@/components/infoPanel/index.vue'
import popupDialog from '@/components/popupDialog/index.vue'
import {Search} from 'vant'
import groupMixins from '../mixins/groupList'
import groupApi from '@/api/group'
import postApi from '@/api/post'
import scroll from '@/common/mixins/scroll'
import {mapGetters} from 'vuex'

import scrollTop from '../home/mixins/top.js'

export default {
  components: {
    infoPanel,
    Search,
    popupDialog,
  },
  mixins: [groupMixins, scroll, scrollTop],
  data: ()=> ({
    show: false,
    showPost: false,
    word: '',
    companyId: null,
    companyGroupList: [],
    groupList: [],
    companyName: '',
    groupName: '',
    groupId: null,
    pageNo: 1,
    pageSize: 20,
    isEnd: false,
    groupInfo: null,
    postList: []
  }),
  watch: {
    word() {
      this.$global.debounce(()=> {
        this.init()
        this.getList()
      }, 400)
    }
  },
  computed: {
    ...mapGetters(['get_router'])
  },
  methods: {
    getGroupList() {
      return this._getCompanyGroupList().then(res => {
        this.companyGroupList = res.data.companyGroupList
        const companyId = this.$route.query.companyId
        this.companyId = companyId
        const groupInfo = this.$global.getItem('groupInfo', true)
        this.groupList = this._getGroupList(this.companyGroupList, companyId)
        this.companyName = this._getCurrentCompany(this.companyGroupList, companyId).companyName
        if (!this.groupInfo || !groupInfo) {
          const groupInfo = this.groupList.find(item => item.groupId == this.$route.query.groupId) || this.groupList[0]
          // this.groupName = groupInfo.groupName
          this.groupId = groupInfo.groupId
          this.$global.setItem('groupInfo', groupInfo, true)
          this.$set(this, 'groupInfo', groupInfo)
        } else {
          const groupInfo = this.groupList.find(item => item.groupId == this.$route.query.groupId) || this.groupList[0]
          // this.groupName = groupInfo.groupName
          this.groupId = groupInfo.groupId
          this.$global.setItem('groupInfo', groupInfo, true)
          this.$set(this, 'groupInfo', groupInfo)
        }
        // alert(0)
        return res
      })
    },
    searchPost() {
      postApi.searchPost({
        searchWord: this.word,
        bizType: 5,
        bizId: this.groupInfo.groupId,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(res => {
        if (res.data) {
          this.postList.push(...res.data.postsList)
          if (res.data.endFlag) {
            this.isEnd = true
          } else {
            this.pageSize++
          }
        }
      })
    },
    changeGroupName(e) {
      this.groupName = e.item.groupName
      this.groupId = e.item.groupId
      this.show = false
      this.$set(this, 'groupInfo', e.item)
      this.$global.setItem('groupInfo', e.item, true)
      this.init()
      this._getList(true)
    },
    postFn() {
      const companyId = this.$route.query.companyId || this.$global.getItem('companyInfo', true).companyId
      this.$router.push({
        path: '/post',
        query: {
          postsSource: 1,
          groupId: this.$route.query.groupId,
          companyId: companyId,
          groupName: this.groupInfo.groupName,
          keyword: this.$route.query.keyword
        }
      })
    },
    _getList(change=false) {
      if (change) {
        this.isEnd = false
        this.postList = []
        this.pageNo = 1
      }
      if (!this.isEnd) {
        groupApi.groupPostList({
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          groupId: this.groupInfo.groupId
        }).then(res => {
          if (res.data) {
            this.postList.push(...res.data.postsList)
            if (res.data.endFlag) {
              this.isEnd = true
            } else {
              this.pageNo++
            }
          }
        })
      }
    },
    init() {
      this.pageNo = 1
      this.isEnd = false
      this.postList = []
    },
    getList() {
      if (this.word) {
        this.searchPost()
      } else {
        this._getList()
      }
    },
    goInfo() {
      // const companyInfo = this.$global.getItem('companyInfo')
      const companyId = this.$route.query.companyId
      this.$router.push({
        path: '/group/info',
        query: {
          companyId: companyId,
          groupId: this.groupInfo.groupId
        }
      })
    },
    showPostFn() {
      const auth = this.$global.getItem('auth')
      if (auth && auth.userAuthMap) {
        // console.log(auth);
        const {groupIconAuth} = auth.userAuthMap
        if (!groupIconAuth || (groupIconAuth.hasAuth == 2)) {
          // this.$toast(groupIconAuth.hasNotAuthTips)
          // return
          this.showPost = false
        } else {
          this.showPost = true
        }
      }
    },
 

    scrollFn(e) {
      this.$global.debounce(()=> {
        this.$router.history.current.meta.offsetTop = e.target.scrollTop
        const wrapH = this.$refs?.wrap?.clientHeight
        const contentH = this.$refs?.content?.clientHeight
        if (wrapH && contentH) {
          if (contentH - e.target.scrollTop - wrapH <= 80) {
            this.getList()
          }
        }
      })
    },

     scrollTopFn(h) {
      setTimeout(() => {
        this.$refs.wrap.scrollTop = h
      }, 20)
    },
  },

  
  mounted() {
    
    this.showPostFn()
    this.getGroupList().then(()=> {
      this.getList()
    })

   
    this.$route.meta.change = ()=> {
      console.log("group route change", this.$route)
      setTimeout(() => {
        const wrap = this.$refs.wrap.$refs.pageContent_contain
        wrap.scrollTop = this.$router?.history?.current?.meta?.offsetTop || 0
      }, 20)
    }

    const wrap = this.$refs.wrap.$refs.pageContent_contain
    const content = this.$refs.content
    this.onContentScroll(wrap, content, this.isEnd, ()=> {
      console.log("onContentScroll")
      this.getList()
    })
  },
  beforeRouteEnter (to, from, next) {
      next((vm) => {


        if (from.name != 'postChoose' && from.name != 'postList') {
          console.log("init group detail")
          vm.init()
          vm.showPostFn()
          vm.getGroupList().then(()=> {
          vm.getList()
        })
        }

        

      })
    },
}
</script>

<style lang="less" scoped>
.detailPage {
  position: relative;
  padding: 15px;
  padding-top: 0;
}
</style>
